import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA } from '@wix/app-definition-ids';
import { maMiscClickUou } from '@wix/bi-logger-members-app-uou/v2';
import { WidgetId } from '@wix/members-area-app-definitions';
import model from './model';
import { getOnlyOrderedMembers } from '../../services/membersOrdering';
import { MemberData, MembersPublicAPI, OrderedMemberIds } from '../../types';
import {
  ABOUT_APP_DEF_ID,
  ABOUT_SECTION_ID,
  WidgetElementId,
} from '../../editorConstants';
import { maybeUpscale } from '../../services/imageUpscaling';
import { getMembers } from '../../server/members.api';
import { PLACEHOLDER_PROFILE_IMAGE_URL } from '../../constants';
import { URL_FIELD } from '../../server/constants';
import { Experiment } from '../../types/experiments';

const bindRepeaterData = async (
  flowAPI: PlatformControllerFlowAPI,
  members: MemberData[],
  $bind: any,
) => {
  const membersApi: MembersPublicAPI =
    await flowAPI.controllerConfig.wixCodeApi.site
      .getPublicAPI(MEMBERS_AREA)
      .catch((error) => {
        flowAPI.errorMonitor.captureException(error as Error);
      });

  const shouldCollapseProfileButton = !(await membersApi.isAppSectionInstalled({
    sectionId: ABOUT_SECTION_ID,
    appDefinitionId: ABOUT_APP_DEF_ID,
    widgetId: WidgetId.About,
  }));

  $bind(WidgetElementId.repeater, {
    data: () => members,
    item(member: MemberData, $bindItem: Function) {
      $bindItem(WidgetElementId.memberName, {
        text: () => member.profile?.nickname,
      });
      $bindItem(WidgetElementId.companyName, {
        text: () => member?.contact?.company,
        collapsed: () => !member?.contact?.company,
      });
      $bindItem(WidgetElementId.memberImage, {
        src: () =>
          member.profile?.photo?.url
            ? maybeUpscale(member.profile?.photo?.url)
            : PLACEHOLDER_PROFILE_IMAGE_URL,
      });
      $bindItem(WidgetElementId.roleDescription, {
        text: () => member?.profile?.title,
        collapsed: () => !member?.profile?.title,
      });
      $bindItem(WidgetElementId.about, {
        text: () => member.about,
        collapsed: () => !member.about,
      });

      const link = member.contact?.customFields?.[URL_FIELD.key]?.value;
      $bindItem(WidgetElementId.link, {
        onClick: () => flowAPI.controllerConfig.wixCodeApi.location.to?.(link),
        collapsed: () => !link,
      });

      $bindItem(WidgetElementId.profileButton, {
        onClick: () => {
          flowAPI?.bi?.report(
            maMiscClickUou({
              referralInfo: 'staff_widget_view_profile',
            }),
          );
          membersApi?.navigateToMember({
            memberId: member.id!,
            memberSlug: member.profile?.slug ?? undefined,
          });
        },
        collapsed: () => shouldCollapseProfileButton,
      });
    },
  });
};

const getMembersData = async (flowAPI: PlatformControllerFlowAPI) => {
  const { data: members } = await flowAPI.httpClient.request(
    getMembers({
      roleIds: [],
      isMembersAboutV2Enabled: flowAPI.experiments.enabled(
        Experiment.UseMembersAboutV2,
      ),
    }),
  );
  return members;
};

export default model.createController(
  ({ flowAPI, controllerConfig: { config, compId, wixCodeApi }, $bind }) => ({
    pageReady: async () => {
      if (flowAPI.environment.isBlocksPreview) {
        return;
      }
      const orderProp = ((config as any).order as OrderedMemberIds) ?? [];

      const warmupDataKey = `${compId}-members`;
      const { warmupData } = wixCodeApi.window;
      const members: MemberData[] =
        warmupData.get(warmupDataKey) ?? (await getMembersData(flowAPI));
      if (flowAPI.environment.isSSR) {
        warmupData.set(warmupDataKey, members);
      }
      const orderedMembers = getOnlyOrderedMembers(members, orderProp);

      await bindRepeaterData(flowAPI, orderedMembers, $bind);
    },
    exports: {},
  }),
);
