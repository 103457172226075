import { dsl } from '@wix/yoshi-serverless/wrap';

export const getMembers = dsl({
          functionName: 'getMembers',
          fileName: 'server/members.api',
        });

export const initFields = dsl({
          functionName: 'initFields',
          fileName: 'server/members.api',
        });