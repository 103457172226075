import {
  Origin,
  Privacy,
  Section,
  SocialTypeType,
  Type,
} from '@wix/ambassador-members-v1-custom-field/types';

export const TITLE_FIELD = {
  fieldOrigin: Origin.SYSTEM,
  fieldType: Type.TEXT,
  name: 'Title',
  key: 'customfields.system.title',
  socialType: SocialTypeType.UNKNOWN,
  defaultPrivacy: Privacy.PUBLIC,
};

export const COMPANY_FIELD = {
  fieldOrigin: Origin.SYSTEM,
  fieldType: Type.TEXT,
  name: 'Company',
  key: 'customfields.contact.company',
  defaultPrivacy: Privacy.PUBLIC,
  socialType: SocialTypeType.UNKNOWN,
};

export const URL_FIELD = {
  fieldType: Type.URL,
  name: 'Link',
  key: 'custom.link',
  defaultPrivacy: Privacy.PUBLIC,
  section: Section.GENERAL,
};
