export const widgetId = '57074dda-cdaf-4df5-8919-a41f25911331';

export const widgetName = 't45j1';

export enum Variant {
  Cards = 'variants-l4r5atwd',
  SideBySide = 'variants-kuzk65wk',
  Mobile = 'variants-kuwcdv8s',
}

export enum ElementId {
  memberImage = 'memberImage',
  memberName = 'memberName',
  roleDescription = 'roleDescription',
  companyName = 'companyName',
  about = 'about',
  link = 'link',
  profileButton = 'profileButton',
  repeater = 'repeater1',
}

export enum WidgetElementId {
  memberImage = '#memberImage',
  memberName = '#memberName',
  roleDescription = '#roleDescription',
  companyName = '#companyName',
  about = '#about',
  link = '#link',
  profileButton = '#profileButton',
  repeater = '#repeater1',
}

export enum PanelType {
  Preset = 'Preset',
  Display = 'Display',
  Staff = 'Staff',
  Link = 'Link',
}

export const INITIALLY_VISIBLE_MEMBERS = 3;

export enum ControllerConfigKeys {
  ORDER_PROP_NAME = 'order',
}
export const MAX_VISIBLE_MEMBERS = 45;

export const ABOUT_APP_DEF_ID = '14dbef06-cc42-5583-32a7-3abd44da4908';
export const ABOUT_SECTION_ID = 'about';
export const STAFF_WIDGET_APP_DEF_ID = 'cffc6740-8042-48cc-a35b-d3fd03a69f0c';

export const MAIN_INTERACTION_NAME = 'Featured Members init';
