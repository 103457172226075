import { OrderedMemberIds, MemberData } from '../types';

interface MembersById {
  [key: string]: MemberData;
}

const normalizeMembersArray = (members: MemberData[]) =>
  members.reduce<MembersById>(
    (acc, curr) => ({ ...acc, [curr._id]: curr }),
    {},
  );

export const getOnlyOrderedMembers = (
  members: MemberData[],
  order: OrderedMemberIds,
) => {
  const membersById = normalizeMembersArray(members);

  const orderedMembers = order.reduce(
    (acc: MemberData[], curr) =>
      membersById[curr] ? [...acc, membersById[curr]] : acc,
    [],
  );

  return orderedMembers ?? [];
};

export const getOrderedMembers = (
  members: MemberData[],
  order: OrderedMemberIds,
) => {
  const unorderedMembers = members.filter(
    (member) => !order.includes(member._id),
  );
  const orderedMembers = getOnlyOrderedMembers(members, order);

  return [...orderedMembers, ...unorderedMembers];
};
